@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kalam&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sofia&display=swap);
/* text-variant */ /* 400 font-family: 'Cedarville Cursive', cursive; */

:root {
  --canvasBG: #f0f0f0;
  --border: #ddd;
  --bg: #fff;
  --font-for-card0: 'Cedarville Cursive', cursive;
  /* --font-for-card0: 'Kalam', cursive; */
  /* --font-for-card1: 'Homemade Apple', cursive;
  --font-for-card2: 'Cedarville Cursive', cursive;
  --font-for-card3: 'La Belle Aurore', cursive;
  --font-for-card4: 'Reenie Beanie', cursive;
  --font-for-card5: 'Satisfy', cursive; */
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
[placeholder]:focus::-webkit-input-placeholder {
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear; 
  opacity: 0;
}
input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* Firefox 18- */
input:focus::-moz-placeholder { color:transparent; } /* Firefox 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* oldIE ;) */
body, *, p {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100%;
  height: 100%;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

